<template>
  <LogoModal
    :inner-container-styles="getModalSize"
    inner-container-classes="form-modal-size"
    :grow-with-content="true"
    :fill-screen="true"
    :buttons="modalButtons"
    @button-clicked="handleModalButtonClick"
    :show="show"
    @update:show="closeModal"
  >
    <FormLayout
      @savedFormLayout="handleSavedForm"
      v-model="formData"
      :product-root-id="getGlobalSelectedProductRootId"
      :form-location-root-id="formLocationRootId"
      v-model:isFormEmpty="isFormEmpty"
      v-model:formHeight="formHeight"
      :hasRightMargin="true"
      :show-save-buttons-on-form="false"
      :configurationData="localConfigurationData"
      @update:configurationData="handleUpdateConfigData"
      v-model:saveFunction="formSaveFunction"
      @cancelClicked="closeModal"
      v-model:formLayoutRootId="localFormLayoutRootId"
    />
    <div v-if="isFormEmpty" class="text-center">
      <div class="text-2xl font-bold">No Form Data Found Please Contact Your System Administrator</div>
    </div>
  </LogoModal>
</template>

<script>
import { computed, defineAsyncComponent, nextTick, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import _ from "lodash";

import LogoModal from "@/components/uIElements/LogoModal.vue";
import {
  CreateNewOrderModalFormLocationName,
  OrderEditorTemplateTasksModalFormLocationName,
  OrderEditorFreeFormTasksModalFormLocationName,
  OrderEditorCompanyContactsModalFormLocationName,
  OrderEditorPersonContactsModalFormLocationName,
  OrderEditorDocumentsModalFormLocationName,
} from "@/lib/settings";

import { ORDER, ORDER_ROOT_ID } from "@/types/formLayoutConfigurationTypes";
export default {
  name: "FormViewerModal",
  components: {
    LogoModal,
    FormLayout: defineAsyncComponent(() => import("@/components/templateBuilder/renderedFormLayout/FormLayout.vue")),
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    modalType: {
      type: String,
      default: "",
    },
    orderRootId: {
      Type: Number,
      default: 0,
    },
  },
  emits: ["update:show", "saved", "update:formSaveData", "save"],
  setup(props, { emit }) {
    const store = useStore();
    // Getters
    const getGlobalSelectedProductRootId = computed(() => store.getters.getGlobalSelectedProductRootId);
    // State
    const productFormLocations = computed(() => store.state.pfl.productFormLocations);

    const formData = ref({});
    watch(
      formData,
      (is) => {
        emit("update:formSaveData", { FormLayoutRootId: localFormLayoutRootId.value, FormData: is });
      },
      {
        deep: true,
      }
    );
    const localFormLayoutRootId = ref(0);
    const formHeight = ref(0);
    const isFormEmpty = ref(false);
    const localConfigurationData = ref({});
    const formSaveFunction = ref(() => {});
    const modalButtons = computed(() => {
      return [
        {
          Id: 1,
          text: "Cancel",
          isDisabled: false,
          cssClass: "btn btn-secondary m-1",
          type: "cancel",
        },
        {
          Id: 2,
          text: "Create",
          isDisabled: false,
          cssClass: "btn btn-primary m-1",
          type: "save",
        },
      ];
    });

    const formLocationRootId = computed(() => {
      switch (props.modalType) {
        case CreateNewOrderModalFormLocationName:
          return productFormLocations.value.find((location) => (location.ProductLocationName || "") === CreateNewOrderModalFormLocationName)?.FormLocationRootId || 0;
        case OrderEditorTemplateTasksModalFormLocationName:
          return productFormLocations.value.find((location) => (location.ProductLocationName || "") === OrderEditorTemplateTasksModalFormLocationName)?.FormLocationRootId || 0;
        case OrderEditorFreeFormTasksModalFormLocationName:
          return productFormLocations.value.find((location) => (location.ProductLocationName || "") === OrderEditorFreeFormTasksModalFormLocationName)?.FormLocationRootId || 0;
        case OrderEditorCompanyContactsModalFormLocationName:
          return productFormLocations.value.find((location) => (location.ProductLocationName || "") === OrderEditorCompanyContactsModalFormLocationName)?.FormLocationRootId || 0;
        case OrderEditorPersonContactsModalFormLocationName:
          return productFormLocations.value.find((location) => (location.ProductLocationName || "") === OrderEditorPersonContactsModalFormLocationName)?.FormLocationRootId || 0;
        case OrderEditorDocumentsModalFormLocationName:
          return productFormLocations.value.find((location) => (location.ProductLocationName || "") === OrderEditorDocumentsModalFormLocationName)?.FormLocationRootId || 0;
        default:
          console.warn("Failed to find form location root id for modal type: ", props.modalType);
          return 0;
      }
    });

    const getModalSize = computed(() => {
      return {
        height: `${formHeight.value}px !important`,
      };
    });

    function closeModal() {
      emit("update:show", false);
      formData.value = {};
    }

    function handleUpdateConfigData(value) {
      localConfigurationData.value = _.cloneDeep(value);
    }

    function handleModalButtonClick(button) {
      switch (button.type) {
        case "cancel":
          closeModal();
          break;
        case "save":
          switch (props.modalType) {
            case CreateNewOrderModalFormLocationName:
              emit("save");
              return;
            default:
              break;
          }
          formSaveFunction.value?.();
          break;
        default:
          console.warn("Unknown button type: ", button.type);
          break;
      }
    }

    function handleSavedForm() {
      emit("saved");
      closeModal();
    }

    onMounted(async () => {
      await nextTick();
      localConfigurationData.value[ORDER] = { RootId: props.orderRootId };
      localConfigurationData.value[ORDER_ROOT_ID] = props.orderRootId || 0;
    });

    return {
      emit,

      localFormLayoutRootId,
      formData,
      isFormEmpty,
      formLocationRootId,
      formHeight,
      modalButtons,
      formSaveFunction,
      localConfigurationData,
      getModalSize,

      getGlobalSelectedProductRootId,

      closeModal,
      handleModalButtonClick,
      handleUpdateConfigData,
      handleSavedForm,
    };
  },
};
</script>

<style>
.form-modal-size {
  min-height: 50vh;
  max-height: 80vh;
}
</style>
